import DiamondBaseAPI from "@/api/Api"
import News from "@/types/News/News"
import NewsDetail from "@/types/News/NewsDetail"
import ResponseBase from "@/types/ResponseBase"
import Year from "@/types/News/Year"

export default class NewsApi extends DiamondBaseAPI {
  static listYears(): Promise<ResponseBase<Year>> {
    return this.api.get("news-year").then(({ data }) => data)
  }

  static getNews(id: string | number): Promise<ResponseBase<NewsDetail>> {
    return this.api.get(`news/${id}`).then(({ data }) => data)
  }

  static getAllNews(): Promise<ResponseBase<NewsDetail>> {
    return this.api.get("news").then(({ data }) => data)
  }

  static listNews(year: number): Promise<ResponseBase<News[]>> {
    return this.api
      .get("news", {
        params: { year }
      })
      .then(({ data }) => data)
  }
}
