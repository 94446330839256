
























import { Component, Vue, Watch } from "vue-property-decorator"
import SectionUnderLine from "@/components/Global/SectionUnderLine.vue"
import TitleShape from "@/components/TitleShape.vue"
import ViewWrapper from "@/views/ViewWrapper.vue"
import NewsApi from "@/api/News/NewsApi"
import { deserialize } from "typescript-json-serializer"
import NewsDetail from "@/types/News/NewsDetail"
import BackBar from "@/components/BackBar.vue"

@Component({
  name: "NewsDetailPage",
  components: {
    BackBar,
    ViewWrapper,
    SectionUnderLine,
    TitleShape
  }
})
export default class NewsDetailPage extends Vue {
  private news = new NewsDetail()

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange() {
    // Router from vue-router
    this.fetchData()
  }

  private fetchData() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const { id } = this.$route.params
    if (!id) {
      this.$router.push({ name: "NewsListPage" })
    }
    NewsApi.getNews(id).then(({ data }) => {
      this.news = deserialize<NewsDetail>(data, NewsDetail)
    })
  }

  mounted() {
    window.scrollTo({ top: 0 })
    this.fetchData()
  }

  toNewsList() {
    const lang = this.$i18n.locale
    const year = `${this.news.year}`
    this.$router.push({ name: "NewsListPage", query: { lang, year } })
  }
}
